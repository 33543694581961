<template>
	<form-modal
		:title="_title"
		ref="formmodal"
		@save="handleSubmit"
		@cancel="cancel"
	>
		<b-row>
			<form-input
				label="หมายเลขโทรศัพท์"
				v-model="form.phone"
				v-validate="`required`"
				data-vv-as="หมายเลขโทรศัพท์"
				:col="6"
			/>
			<form-input
				label="รายละเอียด"
				v-model="form.note"
				data-vv-as="รายละเอียด"
				:col="6"
			/>
		</b-row>
	</form-modal>
</template>
<script>
export default {
	computed: {
		_title() {
			const form = this.form
			let _msg = ''
			if (typeof form.originalIndex === 'number') {
				_msg = 'แก้ไขข้อมูล เบอร์โทรศัพท์'
			} else {
				_msg = 'เพิ่มข้อมูล เบอร์โทรศัพท์'
			}
			return _msg
		},
	},
	data() {
		return {
			form: {
				phone: '',
				note: '',
			},
		}
	},
	methods: {
		show(data = '') {
			if (data) {
				this.form = data
			} else {
				this.clearForm()
			}

			this.switch('show')
		},
		async handleSubmit() {
			try {
				const validatetor = await this.$validator.validateAll()
				if (!validatetor) {
					await this.alert.validator()
					return ''
				}
				this.$emit('callBackFormModal', this.convert.cp(this.form))
				this.clearForm()
			} catch (error) {
				console.error(error)
				return ''
			}
		},
		cancel() {
			this.clearForm()
		},
		clearForm() {
			const form = this.form
			Object.keys(form).forEach((fill) => [(form[fill] = '')])
			this.switch('hide')
		},
		switch(type) {
			type === 'show'
				? this.$refs.formmodal.show()
				: this.$refs.formmodal.hide()
		},
	},
}
</script>
